// src/hooks/useContract.js
import { useEffect, useState } from 'react';
import { BrowserProvider, Contract, formatUnits } from 'ethers'
import NodesABI from '../abis/NodesABI.json'; // ABI of your contract
import CollectionABI from '../abis/CollectionABI.json'; // ABI of your contract
import SETTINGS from "../SETTINGS";

export const useContract = (provider) => {
    const [collections, setCollections] = useState([]);
    const [ownedNFTs, setOwnedNFTs] = useState([]);
    const [ownedQworlds, setOwnedQworlds] = useState([]);
    const [distinctElements, setDistinctElements] = useState([]);
    const [NFTTypeCount, setNFTTypeCount] = useState({});
    const [totalRewards, setTotalRewards] = useState(0);
    const [totalDailyRewards, setTotalDailyRewards] = useState(0);
    const [airdropPower, setAirdropPower] = useState(0);
    const [ownedNFTsLoaded, setOwnedNFTsLoaded] = useState(true);
    const fetchCollections = async () => {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        const contract = new Contract(SETTINGS.nodeContract, NodesABI, signer);
        let collectionsArr = [];
        const collection = await contract.collection();
        const collectionContract = new Contract(collection, CollectionABI, signer);
        const minted = await collectionContract.minted();
        const sold = await collectionContract.sold();
        const price = await contract.getPrice(sold);
        const tokenURI = await collectionContract.tokenURI(minted);
        const response = await fetch(tokenURI);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const json = await response.json();
        collectionsArr.push({
            name: json.name,
            symbol: SETTINGS.collectionSymbol,
            imageURL: json.image,
            priceUSDTWei: price,
            priceUSDT: formatUnits(price, 6),
            maxSupply: parseInt(SETTINGS.MAX_SUPPLY),
            minted: parseInt(minted),
            sold: parseInt(sold),
            isAvailable: true,
            availableToMint: parseInt(SETTINGS.MAX_SUPPLY) - parseInt(sold)
        });
        setCollections(collectionsArr);
    };

    const getDailyRewardTokenType = (tokenType) => {
        if (tokenType == 1) return SETTINGS.DAILY_TOKENS * 1;
        if (tokenType == 2) return SETTINGS.DAILY_TOKENS * 2;
        if (tokenType == 3) return SETTINGS.DAILY_TOKENS * 4;
        if (tokenType == 4) return SETTINGS.DAILY_TOKENS * 8;
        if (tokenType == 5) return SETTINGS.DAILY_TOKENS * 16;
        if (tokenType == 1337) return SETTINGS.DAILY_TOKENS * 32;
        return 0;
    };

    const countUserNFTs = async () => {
        try {
            /*
            const ethersProvider = new BrowserProvider(provider);
            const signer = await ethersProvider.getSigner();
            const contract = new Contract(SETTINGS.nodeContract, NodesABI, signer);
            const account = await signer.getAddress();
            const nftsForUser = await contract.getAllNFTsForUser(account);
            console.log(nftsForUser);
            */
        }
        catch (e) {
            console.log("count user nfts error");
            console.log(e);
        }
    }

    const fetchOwnedNFTs = async () => {
        try {
            setOwnedNFTsLoaded(false);
            const ethersProvider = new BrowserProvider(provider);
            const signer = await ethersProvider.getSigner();
            const contract = new Contract(SETTINGS.nodeContract, NodesABI, signer);
            const collectionContract = new Contract(SETTINGS.collection, CollectionABI, signer);
            const account = await signer.getAddress();
           
            const nftsForUser = await contract.getAllNFTsForUser(account);
            const serialized = JSON.stringify(nftsForUser, (key, value) =>
                typeof value === 'bigint' ? value.toString() : value
            );
            const nfts = JSON.parse(serialized);
            const nftDataPromises = nfts.map(async (nft) => {
                let unclaimedRewards = "0";
                try {
                    const calculateRewards = await collectionContract.calculateRewards(parseInt(nft[1]));
                    unclaimedRewards = formatUnits(calculateRewards.toString(), 18);
                } catch (error) {
                    console.log("error calculate rewards");
                }

                const response = await fetch(nft[3]);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const json = await response.json();

                const tokenType = await collectionContract.tokenTypes(parseInt(nft[1]));
                const lastClaim = await collectionContract.lastClaim(parseInt(nft[1]));
                const dailyReward = getDailyRewardTokenType(parseInt(tokenType));

                return {
                    collectionAddress: nft[0],
                    tokenId: nft[1],
                    name: json.name,
                    imageURL: json.image,
                    tokenType: tokenType.toString(),
                    dailyReward: dailyReward.toString(),
                    lastClaim: lastClaim.toString(),
                    unclaimedRewards: parseFloat(unclaimedRewards).toFixed(2),
                    rawTokenType: parseInt(tokenType),
                    rawDailyReward: parseFloat(dailyReward),
                    rawAirdropPower: parseFloat(SETTINGS.NODE_INFO[tokenType.toString()].airdrop_power),
                };
            });

            const nftData = await Promise.all(nftDataPromises);
            console.log(nftData);
            console.log(nftData);
            let nftsArr = [];
            let elementsArr = [];
            let distinctElementsArr = [];
            let ownedQworldsArr = [];
            let totalRewards = 0;
            let totalDaily = 0;
            let totalAirdropPow = 0;
            let nftTypeCount = {};

            nftData.forEach((temp) => {
                totalRewards += parseFloat(temp.unclaimedRewards);
                totalAirdropPow += temp.rawAirdropPower;
                totalDaily += temp.rawDailyReward;

                if (!nftTypeCount[temp.rawTokenType]) {
                    nftTypeCount[temp.rawTokenType] = 0;
                    if (temp.rawDailyReward <= 0) {
                        distinctElementsArr.push(temp);
                    }
                }
                nftTypeCount[temp.rawTokenType] += 1;

                if (temp.rawDailyReward > 0) {
                    nftsArr.push(temp);
                    ownedQworldsArr.push(temp);
                } else {
                    elementsArr.push(temp);
                }
            });

            setTotalRewards(totalRewards.toFixed(2));
            setTotalDailyRewards(totalDaily.toFixed(2));
            setAirdropPower(totalAirdropPow.toFixed(2));
            setOwnedNFTs(nftsArr.concat(elementsArr));
            setDistinctElements(distinctElementsArr);
            console.log(distinctElementsArr);
            setOwnedQworlds(ownedQworldsArr);

            setNFTTypeCount(nftTypeCount);
            setOwnedNFTsLoaded(true);

        } catch (e) {
            console.log("owned nfts error");
            console.log(e);
        }
    };


    useEffect(() => {
        if (!provider) {
            setOwnedNFTs([]);
            setOwnedQworlds([]);
            setDistinctElements([]);
            setTotalDailyRewards(0);
            setTotalRewards(0);
            setAirdropPower(0);
            setOwnedNFTsLoaded(true);
            return;
        }

        fetchCollections();
        fetchOwnedNFTs();
    }, [provider]);

    return { collections, ownedNFTs, fetchCollections, fetchOwnedNFTs, totalRewards, ownedNFTsLoaded, airdropPower, totalDailyRewards, NFTTypeCount, distinctElements, ownedQworlds, countUserNFTs };
};
