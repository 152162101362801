import React, { useState, useEffect } from 'react';

// Array of messages in different languages
const messages = [
  { language: "English", text: "To those who are ready: welcome." },
  { language: "Mandarin", text: "准备好的人：欢迎。" },
  { language: "Hindi", text: "जो तैयार हैं: स्वागत है।" },
  { language: "Spanish", text: "Para aquellos que están listos: bienvenidos." },
  { language: "French", text: "Pour ceux qui sont prêts : bienvenue." },
  { language: "Arabic", text: "لمن هم على استعداد: مرحبا." },
  { language: "Bengali", text: "যারা প্রস্তুত: স্বাগতম।" },
  { language: "Russian", text: "Тем, кто готов: добро пожаловать." },
  { language: "Portuguese", text: "Para aqueles que estão prontos: bem-vindo." },
  { language: "Urdu", text: "جو تیار ہیں: خوش آمدید۔" },
];

const WelcomeMessages = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 5000); // 5000ms = 5 seconds

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  return (
    <div style={{ textAlign: 'center', fontSize: '20px', padding: '0px' }}>
      <p>{messages[index].text}</p>
      
    </div>
  );
};

export default WelcomeMessages;
