import React, { useState } from 'react';
import { BiHome, BiShoppingBag, BiInfoCircle, BiBox, BiCustomize, BiPlanet, BiCoin, BiAdjust } from 'react-icons/bi';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Logo from './logo.svg';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import ConnectButton from './components/ConnectButton';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react';
import Dashboard from './components/Dashboard';
import Nodes from './components/Nodes';
import Staking from './components/Staking';
import Info from './components/Info';
import WorldInfo from './components/WorldInfo';
import NodeMergeSection from './components/NodeMergeSection';
import QvrseLore from './components/QvrseLore';
import FAQs from './components/FAQs';
import Footer from './components/Footer';
import FlipNotification from './components/FlipNotification';
import TokenSale from './components/TokenSale';
import NodesList from './components/NodesList';
import Admin from './components/Admin';
import AdminUsers from './components/AdminUsers';
import AdminUser from './components/AdminUser';
import Marketplace from './components/Marketplace';
import Referral from './components/Referral';
import SETTINGS from './SETTINGS';
import MeteorShower from './components/MeteorShower';

function App() {
  const { address, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [expanded, setExpanded] = useState(false);

  const handleLinkClick = () => {
    setExpanded(false);
  };

  return (
    <Router>

      <Navbar expand="lg" className="top-bar" expanded={expanded}>
        <Container>
          <Navbar.Brand as={Link} to="/" onClick={handleLinkClick}>
            <img src={Logo} style={{ width: "90px" }} alt={"Logo"} />
          </Navbar.Brand>
          <div className="d-block d-lg-none">
            <ConnectButton />
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : 'expanded')} className='px-0' />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto mt-lg-0 mt-4">
              <Nav.Link as={Link} to="/" className="menuBtn" onClick={handleLinkClick}><BiHome /> Home</Nav.Link>
              <NavDropdown title={<span><BiPlanet /> QNODES</span>} id="basic-nav-dropdown" className="menuBtn dropdown-padding">
                <NavDropdown.Item><Nav.Link as={Link} to="/store" onClick={handleLinkClick}>Shop</Nav.Link></NavDropdown.Item>
                {/* <NavDropdown.Item><Nav.Link as={Link} to="/market" onClick={handleLinkClick}>Market</Nav.Link></NavDropdown.Item>*/}
                <NavDropdown.Item><Nav.Link as={Link} to="/list" onClick={handleLinkClick}>My QNODES</Nav.Link></NavDropdown.Item>
                <NavDropdown.Item><Nav.Link as={Link} to="/craft" onClick={handleLinkClick}>Crafting</Nav.Link></NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title={<span><BiCoin /> QVRS Token</span>} id="basic-nav-dropdown" className="menuBtn dropdown-padding">
                <NavDropdown.Item><Nav.Link as={Link} to="/staking" onClick={handleLinkClick}>Stake QVRS</Nav.Link></NavDropdown.Item>
              </NavDropdown>
              
              {isConnected && SETTINGS.owner.some(owner => owner.toLowerCase() === address.toLowerCase()) && (
                <>
                  <NavDropdown title={<span><BiAdjust /> Admin</span>} id="basic-nav-dropdown" className="menuBtn dropdown-padding">
                    <NavDropdown.Item><Nav.Link as={Link} to="/admin" onClick={handleLinkClick}>Admin NFTs</Nav.Link></NavDropdown.Item>
                    <NavDropdown.Item><Nav.Link as={Link} to="/admin-users" onClick={handleLinkClick}>Admin Users</Nav.Link></NavDropdown.Item>

                  </NavDropdown>
                </>
              )}
              <NavDropdown title={<span><BiInfoCircle /> Info</span>} id="basic-nav-dropdown" className="menuBtn dropdown-padding">
                <NavDropdown.Item><Nav.Link as={Link} to="/info" onClick={handleLinkClick}>Token info</Nav.Link></NavDropdown.Item>
                <NavDropdown.Item><Nav.Link as={Link} to="/world-info" onClick={handleLinkClick}>World info</Nav.Link></NavDropdown.Item>
                <NavDropdown.Item><Nav.Link as={Link} to="/lore" onClick={handleLinkClick}>Qvrse Lore</Nav.Link></NavDropdown.Item>
                <NavDropdown.Item><Nav.Link as={Link} to="/FAQs" onClick={handleLinkClick}>FAQs</Nav.Link></NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
          <div className="d-none d-lg-block">
            <ConnectButton />
          </div>
        </Container>
      </Navbar>
      <Container className='pb-5'>
      <MeteorShower />
        <Routes>
          <Route path="/FAQs" element={<FAQs />} />
          <Route path="/lore" element={<QvrseLore />} />
          <Route path="/info" element={<Info provider={walletProvider} />} />
          <Route path="/world-info" element={<WorldInfo provider={walletProvider} />} />
          <Route path="/staking" element={<Staking provider={walletProvider} account={address} isConnected={isConnected} />} />
          <Route path="/store" element={<Nodes provider={walletProvider} account={address} isConnected={isConnected} />} />
          <Route path="/craft" element={<NodeMergeSection provider={walletProvider} account={address} isConnected={isConnected} />} />
          <Route path="/token" element={<TokenSale provider={walletProvider} account={address} isConnected={isConnected} />} />
          <Route path="/market" element={<Marketplace provider={walletProvider} account={address} isConnected={isConnected} />} />
          <Route path="/referral" element={<Referral provider={walletProvider} account={address} isConnected={isConnected} />} />
          <Route path="/" element={<Dashboard provider={walletProvider} address={address} isConnected={isConnected} />} />

          {isConnected && SETTINGS.owner.some(owner => owner.toLowerCase() === address.toLowerCase()) && (
            <>
              <Route path="/admin" element={<Admin provider={walletProvider} address={address} isConnected={isConnected} />} />
              <Route path="/admin-users" element={<AdminUsers provider={walletProvider} isConnected={isConnected} />} />
              <Route path="/user/:userAddress" element={<AdminUser provider={walletProvider} isConnected={isConnected} />} />
            </>
          )}
          <Route path="/list" element={<NodesList provider={walletProvider} address={address} isConnected={isConnected} />} />
        </Routes>
      </Container>
      <Footer />
    </Router>
  );
}

export default App;