import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Card, Alert, Spinner } from 'react-bootstrap';
import { BrowserProvider, Contract, formatUnits, parseUnits } from 'ethers'
import NodesABI from '../abis/NodesABI';
import CollectionABI from '../abis/CollectionABI';
import SETTINGS from '../SETTINGS';
import { ERC20_ABI } from "../abis/erc20";
import { useContract } from '../hooks/useContract';
import logoToken from '../logoToken.svg';
import { useNavigate } from 'react-router-dom';
import ConnectButton from '../components/ConnectButton';
import ReferralButton from '../components/ReferralButton';
import starIcon from '../star.gif';
import starIcon2 from '../star2.gif';
import SuccessModal from '../components/SuccessModal';


const Nodes = ({ provider, account, isConnected }) => {
    const [quantities, setQuantities] = useState([1, 1, 1, 1]);
    const [nodeTypes, setNodeTypes] = useState([10, 20, 42]);
    const { collections, fetchCollections } = useContract(provider);
    const [loading, setLoading] = useState(false);
    const [buyAvailable, setBuyAvailable] = useState(false);
    const [txMessage, setTxMessage] = useState('');
    const [reffCode, setReffCode] = useState('');
    const [reffAddress, setReffAddress] = useState('');
    const [usdtBalance, setUsdtBalance] = useState('0.00');
    const [usdtAllowance, setUsdtAllowance] = useState(0);
    const [qvrsBalance, setQvrsBalance] = useState('0.00');
    const [qvrsAllowance, setQvrsAllowance] = useState(0);
    const [userLevel, setUserLevel] = useState(1);
    const [modalShow, setModalShow] = useState(false);
    const [modalImage, setModalImage] = useState(SETTINGS.NODE_INFO[1].imgUrl);


    const navigate = useNavigate();
    const getUserLevel = async () => {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        const collectionContract = new Contract(
            SETTINGS.collection,
            CollectionABI,
            signer
        );
        const level = await collectionContract.levels(signer.address);
        if (parseInt(level) > 1) {
            setUserLevel(parseInt(level));
        }
    };

    async function getBalance() {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        // The Contract object
        try {
            const USDTContract = new Contract(SETTINGS.usdtAddress, ERC20_ABI, signer);
            const USDTBalance = await USDTContract.balanceOf(account);

            setUsdtBalance(parseFloat(formatUnits(USDTBalance, 6)).toFixed(2));

            const USDTAllowance = await USDTContract.allowance(account, SETTINGS.nodeContract);
            setUsdtAllowance(parseFloat(USDTAllowance));

            const TokenContract = new Contract(SETTINGS.tokenAddress, ERC20_ABI, signer);
            const TokenBalance = await TokenContract.balanceOf(account);
            setQvrsBalance(parseFloat(formatUnits(TokenBalance, 18)).toFixed(2));

            const TokenAllowance = await TokenContract.allowance(account, SETTINGS.nodeContract);
            setQvrsAllowance(parseFloat(formatUnits(TokenAllowance, 18)));


            const NodesContract = new Contract(
                SETTINGS.nodeContract,
                NodesABI,
                signer
            );

            const savedRefferal = await NodesContract.my_referrer(account);
            if (savedRefferal.toLowerCase() === "0x0000000000000000000000000000000000000000") { } else {
                setBuyAvailable(true);
                setReffAddress(savedRefferal);
            }

            await getUserLevel();
        }
        catch (error) {
            console.log("error getBalance");
            console.log(error);
        }
    };

    useEffect(() => {

    }, [collections]);

    useEffect(() => {
        if (isConnected) {
            getBalance();
        }
    }, [isConnected]);

    const incrementQuantity = (index) => {
        setQuantities(qts => qts.map((qty, idx) => idx === index ? qty + 1 : qty));
    };

    const decrementQuantity = (index) => {
        setQuantities(qts => qts.map((qty, idx) => idx === index && qty > 1 ? qty - 1 : qty));
    };

    const _setReffCode = async (code) => {
        setReffCode(code);
        if (code.length <= 3) return;
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        const collectionContract = new Contract(
            SETTINGS.collection,
            CollectionABI,
            signer
        );
        // Call the buy function on the NFT contract
        // Since we're only working with USDT, we don't need to send ETH value
        const refferalAddr = await collectionContract.codeUser(code);
        if (refferalAddr.toLowerCase() === "0x0000000000000000000000000000000000000000") {
            setBuyAvailable(false);
        } else {
            if (refferalAddr.toLowerCase() == signer.address.toLowerCase()) {
                alert("You can't reffer yourself!");
                setBuyAvailable(false);
            } else {
                setBuyAvailable(true);
            }

        }
        setReffAddress(refferalAddr);
    };

    const buyNFT = async (index) => {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        if (!collections || index >= collections.length) return;
        const quantity = quantities[index];
        const usdtAmount = parseInt(collections[index].priceUSDTWei) * parseInt(quantity);

        try {
            setLoading(true);
            setTxMessage("Approving USDC transfer...");
            // Connect to the USDT contract
            const usdtContract = new Contract(
                SETTINGS.usdtAddress,
                ERC20_ABI,
                signer
            );
            // Approve the NFT contract to spend USDT on behalf of the user
            const approveTx = await usdtContract.approve(
                SETTINGS.nodeContract,
                usdtAmount
            );
            await approveTx.wait();
            setTxMessage("Processing the purchase...");
            // Connect to the NFT contract and buy the NFT
            const nftContract = new Contract(
                SETTINGS.nodeContract,
                NodesABI,
                signer
            );
            // Call the buy function on the NFT contract
            // Since we're only working with USDT, we don't need to send ETH value
            const buyTx = await nftContract.buy(quantity, usdtAmount, reffAddress);
            await buyTx.wait();
            setTxMessage("NFT successfully purchased!");
            setModalShow(true);
        } catch (error) {
            console.error("Error purchasing NFT:", error);
            setTxMessage("Failed to purchase NFT. Please try again.");
        } finally {
            await fetchCollections();
            setLoading(false);
            
            // Optionally reset the transaction message after a delay
            setTimeout(() => setTxMessage(""), 5000);

        }
    };

    const buyCard = async (type, index) => {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        const quantity = quantities[index];
        const qvrsAmount = type * parseInt(quantity);
        const qvrsAmountWei = parseUnits(qvrsAmount.toString(), 18);
        try {
            setLoading(true);
            if (qvrsAmount > qvrsAllowance) {
                setTxMessage("Approving " + SETTINGS.tokenSymbol + " transfer...");
                // Connect to the USDT contract
                const qvrsContract = new Contract(
                    SETTINGS.tokenAddress,
                    ERC20_ABI,
                    signer
                );
                // Approve the NFT contract to spend USDT on behalf of the user
                const approveTx = await qvrsContract.approve(
                    SETTINGS.nodeContract,
                    qvrsAmountWei
                );
                await approveTx.wait();
            }

            setTxMessage("Processing the purchase...");
            // Connect to the NFT contract and buy the NFT
            const nftContract = new Contract(
                SETTINGS.nodeContract,
                NodesABI,
                signer
            );
            // Call the buy function on the NFT contract
            // Since we're only working with USDT, we don't need to send ETH value
            const buyTx = await nftContract.buy_card(quantity, qvrsAmountWei, type);
            await buyTx.wait();
            setTxMessage("NFT successfully purchased!");
        } catch (error) {
            console.error("Error purchasing NFT:", error);
            setTxMessage("Failed to purchase NFT. Please try again.");
        } finally {
            await fetchCollections();
            setLoading(false);
            setModalShow(true);
            setModalImage(SETTINGS.NODE_INFO[type].imgUrl);
            // Optionally reset the transaction message after a delay
            setTimeout(() => setTxMessage(""), 5000);
        }
    };
    if (loading) {
        return (
            <div className=" text-center">
                <br />
                <br />
                <Spinner animation="border" role="status" className='loaderBig' />
                <p className='loaderMsg'>{txMessage}</p>
            </div>
        );
    }
    if (collections.length === 0 && isConnected) {
        return (
            <div className=" text-center">
                <br />
                <br />
                <Spinner animation="border" role="status" className='loaderBig' />
                <p className='loaderMsg'>{txMessage}</p>
            </div>
        );
    }

    return (
        <div className="buy-token-container1 mb-4">
            {/* Title */}
            <h3 className='pageTitle'>QNODES SHOP</h3>
            {/* CTA */}
            <Col xs={12} md={10} lg={8} xl={6} className='mt-md-0 mt-3 offset-xl-3 offset-lg-2 offset-md-1'>
                <div className="urgency-message p-3 mb-3" style={{
                    border: '2px solid #f5c6cb',
                    borderRadius: '10px',
                    backgroundColor: '#f8d7da',
                    color: '#721c24',
                    textAlign: 'center',
                }}>
                    <div className='d-flex justify-content-center align-items-center'>
                        <img src={starIcon2} alt="Star Icon" style={{ width: '40px', height: '40px' }} />
                        <h4 className='mb-2 mt-3 mx-2'>GET YOUR QNODES NOW</h4>
                        <img src={starIcon} alt="Star Icon" style={{ width: '40px', height: '40px' }} />
                    </div>
                    <p className=' mt-2'>The first 2,000 QVRS nodes are available at just $25 USDC each.<br></br>After that, <b>the price jumps to $50 USDC! </b></p>
                </div>
            </Col>
            {/* Row */}
            <Row className="mb-5 pb-md-0 pb-4 pt-md-2 pt-0">
                {/* Buy QNODE */}
                <Col xs={12} md={6} lg={6} xl={4} className='mb-md-4 mb-3'>
                    <div>
                        <div className='pokemon-card pokemon-card-height d-flex flex-column justify-content-between'>
                            <div className="pokemon-card-title pt-0 pb-1">
                                <h3 className='mb-md-0 mb-2' style={{ textAlign: "center" }}>BUY QNODE #1</h3>
                                <div className="iframe-containerr d-flex justify-content-center align-items-center">
                                    <iframe src="https://node.qvrse.io/" title="External Content" />
                                </div>
                            </div>
                            {/* Amount */}
                            <div className="pokemon-card-footer" style={{ paddingTop: "6px" }}>
                                <>
                                    <Row className='mb-2'>
                                        <Col xs={3} sm={2} className='offset-sm-3 offset-2'>
                                            <Button variant="outline-secondary" className='buton buton-small' onClick={() => decrementQuantity(0)}>-</Button>
                                        </Col>
                                        <Col xs={2} sm={2}>
                                            <div className='pokemon-card-title h5'>{quantities[0]}</div>
                                        </Col>
                                        <Col xs={3} sm={2}>
                                            <Button variant="outline-secondary" className='buton buton-small' onClick={() => incrementQuantity(0)}>+</Button>
                                        </Col>
                                    </Row>
                                </>
                            </div>
                            {!isConnected ? (
                                <div className='mt-3'>
                                    <div className='input-group' style={{ marginBottom: "0px" }}>
                                        <div className="input-group d-flex justify-content-center align-items-center">
                                            <Alert variant="secondary">
                                                <div className='py-md-3 py-2 px-md-2 px-1 text-center' style={{ margin: "0px 0px" }}>
                                                    <h5>Connect wallet to buy ${SETTINGS.tokenSymbol} tokens</h5>
                                                    <br />
                                                    <ConnectButton />
                                                </div>
                                            </Alert>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className='mt-3'>
                                    <div className="input-group d-flex justify-content-center align-items-center">
                                        <p className='mb-0'>Price:</p>
                                        <img src={SETTINGS.usdtIcon} style={{ width: "18px", marginRight: "8px", marginLeft: "10px", height: "18px" }} alt={"USDC"} />
                                        <p className="mb-0 fw-bold">{collections[0].priceUSDT * quantities[0]} USDC</p>
                                    </div>

                                    {buyAvailable ? (
                                        <div>
                                            <Button variant="primary" onClick={() => buyNFT(0)} className='buton mb-2 mt-0'>
                                                BUY QNODE
                                            </Button>
                                            <center>
                                                <span style={{ marginTop: "6px", display: "block", fontWeight: "500" }}>
                                                    <small>Your balance:</small>
                                                    <img src={SETTINGS.usdtIcon} className='tokenIconColor' style={{ width: "18px", marginRight: "5px", marginLeft: "8px", marginTop: "-2px" }} alt={"USDC"} />
                                                    <small className='fw-bold'>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(usdtBalance)} USDC</small>
                                                </span>
                                            </center>
                                        </div>
                                    ) : (
                                        <div style={{position:"relative"}}>
                                            <div className='input-group' style={{ marginBottom: "10px" }}>
                                                <input
                                                    type="text"
                                                    value={reffCode}
                                                    disabled={!isConnected}
                                                    onChange={(e) => _setReffCode(e.target.value)}
                                                    placeholder="Referral Code"
                                                    className='custom-select'
                                                />
                                                
                                            </div>
                                            <ReferralButton />
                                            <center>
                                                <span style={{ marginTop: "0px", display: "block", fontWeight: "500" }}>
                                                    <small>Your balance:</small>
                                                    <img src={SETTINGS.usdtIcon} className='tokenIconColor' style={{ width: "18px", marginRight: "5px", marginLeft: "8px", marginTop: "-2px" }} alt={"USDC"} />
                                                    <small className='fw-bold'>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(usdtBalance)} USDC</small>
                                                </span>
                                            </center>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </Col>



                {/* Buy Qcard */}
                {nodeTypes.map((type, index) => (
                    <Col xs={12} md={6} lg={6} xl={4} className='mb-3' key={index}>
                        <div>
                            <div className='pokemon-card pokemon-card-height d-flex flex-column justify-content-between'>
                                <div className="pokemon-card-title pt-0 pb-1">
                                    <h3 className='mb-md-0 mb-2' style={{ textAlign: "center" }}>BUY {SETTINGS.NODE_INFO[type].title}</h3>
                                    <Card.Img variant="top" src={"https://node.qvrse.io/planet" + type + ".png"} style={{ width: "auto", height: "auto", maxHeight: "180px" }} />
                                </div>
                                {/* Amount */}
                                <div className="pokemon-card-footer" style={{ paddingTop: "6px" }}>
                                    <>
                                        <Row className='mb-2'>
                                            <Col xs={3} sm={2} className='offset-sm-3 offset-2'>
                                                <Button variant="outline-secondary" className='buton buton-small' onClick={() => decrementQuantity(index + 1)}>-</Button>
                                            </Col>
                                            <Col xs={2} sm={2}>
                                                <div className='pokemon-card-title h5'>{quantities[index + 1]}</div>
                                            </Col>
                                            <Col xs={3} sm={2}>
                                                <Button variant="outline-secondary" className='buton buton-small' onClick={() => incrementQuantity(index + 1)}>+</Button>
                                            </Col>
                                        </Row>
                                    </>
                                </div>
                                {/* Buy */}
                                <div className=''>
                                    <div className="input-group d-flex justify-content-center align-items-center">
                                        <p className='mb-0'>Price:</p>
                                        <img src={logoToken} style={{ width: "18px", marginRight: "8px", marginLeft: "10px", height: "18px" }} alt={SETTINGS.tokenSymbol} />
                                        <p className="mb-0 fw-bold">{parseInt(type) * quantities[index + 1]} {SETTINGS.tokenSymbol}</p>
                                    </div>
                                    {isConnected ? (
                                        <Button variant="primary" onClick={() => buyCard(type, index + 1)} className='buton mb-2'>
                                            BUY
                                        </Button>
                                    ) : (
                                        <Button variant="primary" disabled className='buton buton-disabled mb-2 '>
                                            BUY
                                        </Button>
                                    )}
                                    <center className='mb-1'>

                                        <span style={{ marginTop: "8px", display: "block", fontWeight: "500" }}>
                                            <small>Your balance:</small>
                                            <img src={logoToken} className='tokenIconColor' style={{ width: "18px", marginRight: "5px", marginLeft: "8px", marginTop: "-2px" }} alt={SETTINGS.tokenSymbol} />
                                            <small className='fw-bold'>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(qvrsBalance)} {SETTINGS.tokenSymbol}</small>
                                        </span>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </Col>
                ))}

            </Row>

<SuccessModal show={modalShow} displayImage={modalImage} onHide={() => setModalShow(false)} />
        </div>
    );
};

export default Nodes;
