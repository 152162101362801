import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Lore1Img from '../lore1.jpg';
import Lore2Img from '../lore2.jpeg';
import Lore4Img from '../lore4.jpeg';
import Lore5Img from '../lore5.jpeg';
import WelcomeMessages from './WelcomeMessages';


const QvrseLore = () => {
  return (
    <Container className="px-0 mt-0">
      <Row className="mb-4">
        <Col xs={12} className='mb-md-3 mb-2 mt-md-4 mt-0'>
          <h2 className='text-center'>Qvrse Lore</h2>
          <h5 className='text-center'>Guardians of the Qvrse Universe: A Call to the Dreamers, Rebels, and Builders</h5>
        </Col>
        <Col md={3}></Col>
        <Col md={6}>
          <Card className='mb-3'>
            <Card.Img variant="top" src={Lore1Img} alt="Genesis of Qvrse" />
            <Card.Body>
              <Card.Title>Welcome, wanderer.</Card.Title>
              <Card.Text>
              You’ve found your way to a realm unlike any other, a place where imagination fuels everything. Here in the Qvrse, we’re building something extraordinary—a boundless world that defies the ordinary, where the future is as limitless as our collective creativity.

              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}></Col>
        <Col md={3}></Col>
        <Col md={6}>
          <Card className='mb-3'>
            <Card.Img variant="top" src={Lore2Img} alt="Call to Adventure" />
            <Card.Body>
              <Card.Title>Are you one of us? </Card.Title>
              <Card.Text>
              A Guardian of the Qvrse? Because to thrive in this universe, you have to see beyond the surface. This isn’t a place for those content to follow the script. No, the Qvrse is for the ones who see things differently, who hear a calling that can’t be silenced. It’s for the misfits, the rebels, the visionaries, and the round pegs in the square holes. The Qvrse was made for you.
              <br />
              <br />
              Step into a universe where ownership, creativity, and connection aren’t just buzzwords—they’re woven into the fabric of our world. Here, you’re invited to take part in something bigger than any one of us. Secure your QNODES and lay claim to a part of this exceptional universe. By becoming a Guardian, you’re not just buying a token—you’re building your own legacy in a realm where everyone has the power to shape the future.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}></Col>
        <Col md={3}></Col>
        <Col md={6}>
          <Card className='mb-3'>
            <Card.Img variant="top" src={Lore4Img} alt="The Community of Creators" />
            <Card.Body>
              <Card.Title>The Qvrse doesn’t ask for permission</Card.Title>
              <Card.Text>
       
                The Qvrse doesn’t ask for permission, and it doesn’t obey the limits of yesterday’s vision. Here, you can create your story and unleash your unique spark. Capture your journey and share it with others through digital albums, through NFTs that preserve your creativity for all time.
                <br />
                <br />
                So here’s to you—the dreamers, the explorers, the ones who embrace new worlds and challenge the status quo. You might find yourself celebrated or criticized, but that doesn’t matter here. In the Qvrse, every idea, every creation, every bond you make strengthens the foundation of something revolutionary.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}></Col>
        <Col md={3}></Col>
        <Col md={6}>
          <Card className='mb-4'>
            <Card.Img variant="top" src={Lore5Img} alt="The Legacy" />
            <Card.Body>
              <Card.Title>Explore the Qvrse</Card.Title>
              <Card.Text>
               and meet the other pioneers shaping this world alongside you. Connect, collaborate, and celebrate the unknown as we forge ahead. This is a journey, a challenge, and a rebellion against a world too quick to settle.

<WelcomeMessages />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

      </Row>

      {/* 
 <Row className="mb-4">
        <Col>
          <Card className='mb-3'>
            <Card.Img variant="top" src={Lore2Img} alt="Call to Adventure" />
            <Card.Body>
              <Card.Title>The Call to Adventure</Card.Title>
              <Card.Text>
                Each new inhabitant of Qvrse begins their journey on a humble, barren planet, a blank canvas waiting to be transformed. These nascent worlds hold the potential for greatness, awaiting the touch of their creators. Through exploration, resource gathering, and the mastery of crafting, users can evolve their planets, imbuing them with unique features, landscapes, and ecosystems.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
     
      
        <Col>
          <Card className='mb-3'>
            <Card.Img variant="top" src={Lore4Img} alt="Community of Creators" />
            <Card.Body>
              <Card.Title>The Community of Creators</Card.Title>
              <Card.Text>
                Qvrse is not a solitary journey. It is a vibrant community where creators connect, collaborate, and compete. Users can visit each other's planets, share knowledge, trade resources, and embark on joint ventures. The collective growth of the community propels Qvrse to new heights, making it a dynamic, ever-evolving universe.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
    
        <Col>
          <Card className='mb-3'>
            <Card.Img variant="top" src={Lore5Img} alt="Legacy" />
            <Card.Body>
              <Card.Title>The Legacy</Card.Title>
              <Card.Text>
                As each user carves their legacy in Qvrse, they contribute to a grand mosaic of creativity and innovation. The legends of the most remarkable planets and their creators are celebrated, inspiring new generations to push the boundaries of what is possible.
                <br /><br />
                Welcome to Qvrse, where your imagination is the only limit. Embark on your journey, craft your world, and ascend to greatness in this unparalleled virtual reality space. The adventure begins now.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    */}
    </Container>
  );
};

export default QvrseLore;
