import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaInstagram, FaTelegramPlane } from 'react-icons/fa';
import { BiRocket, BiCard } from 'react-icons/bi';

const TwitterIcon = () => (
  <svg className="twitter" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="19" height="19">
    <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
  </svg>
);

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="justify-content-center">
          <Col className="text-center">
        
            <a href="https://x.com/QvrseDAO/" target="_blank" rel="noopener noreferrer me" className="social-icon twitter">
              <TwitterIcon />
            </a>
            <a href="https://www.instagram.com/qvrse.io/" target="_blank" rel="noopener noreferrer me" className="social-icon instagram">
              <FaInstagram size={21} />
            </a>
            <a href="https://qvrse.io" target="_blank" rel="noopener noreferrer me" className="social-icon platform">
              <BiCard size={21} className='me-1' /> Qvrse.io
            </a>
            <a href="https://www.spatial.io/s/QvrseStation-6655c097311b7a21439823eb?share=3953462147396508479" target="_blank" rel="noopener noreferrer me" className="social-icon station">
              <BiRocket size={20} className='me-1' /> Qvrse Station
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
