import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { BiCheckCircle } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';

function SuccessModal({ show, displayImage, onHide }) {
    const { width, height } = useWindowSize(); // To cover the full window size with confetti
    const navigate = useNavigate();


    const openPortfolio = () => {
        navigate('/list');
      }
    const openCrafting = () => {
        navigate('/craft');
      }
  return (
    <>{show && <Confetti width={width} height={height} />}
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header >
      
      </Modal.Header>
      <Modal.Body className="text-center">
      
        <h4 className="mt-3">Purchase Successful!</h4>
        <img src={displayImage} style={{width:"150px"}} className='floatAvatar mx-auto mb-3' />
        <p>Your purchase has been completed successfully.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" className='buton' onClick={openPortfolio}>
         My Qnodes
        </Button>
        <Button variant="success" className='buton' onClick={openCrafting}>
         Open Crafting
        </Button>
        <Button variant="secondary" className='buton buton-secondary' onClick={onHide}>
          Back to Store
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  );
}

export default SuccessModal;
