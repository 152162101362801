import React, { useEffect, useState } from 'react';
import '../MeteorShower.css';

const MeteorShower = () => {
  const [meteors, setMeteors] = useState([]);

  useEffect(() => {
    const generateMeteor = () => {
      const newMeteors = [];

      // Generate multiple meteors at once (e.g., 2-4 per interval)
      const meteorCount = Math.floor(Math.random() * 20) + 10; // Randomly 2 to 4 meteors

      for (let i = 0; i < meteorCount; i++) {
        // Random properties for each meteor
        const leftPosition = Math.random() * window.innerWidth;
        const duration = Math.random() * 2 + 1; // Random duration between 1s to 3s
        const delay = Math.random() * 5; // Random delay up to 5 seconds

        // Create a new meteor object
        newMeteors.push({
          id: `${Date.now()}-${i}`, // Unique ID for each meteor
          left: leftPosition,
          duration,
          delay
        });
      }

      // Add new meteors to the array, and remove them after they complete the animation
      setMeteors((prevMeteors) => [...prevMeteors, ...newMeteors]);
      newMeteors.forEach((meteor) => {
        setTimeout(() => {
          setMeteors((prevMeteors) => prevMeteors.filter((m) => m.id !== meteor.id));
        }, (meteor.duration + meteor.delay) * 1000);
      });
    };

    // Shorter interval to generate meteors more frequently
    const interval = setInterval(generateMeteor, Math.random() * 1000 + 500); // Every 0.5 - 1.5 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="meteor-shower">
      {meteors.map((meteor) => (
        <div
          key={meteor.id}
          className="meteor"
          style={{
            left: meteor.left,
            animationDuration: `${meteor.duration}s`,
            animationDelay: `${meteor.delay}s`
          }}
        />
      ))}
    </div>
  );
};

export default MeteorShower;
