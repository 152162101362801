import React, { useEffect, useState } from 'react';
import parachuteImg from '../parachute.png';
import logoToken from '../logoToken.svg';
import { useContract } from '../hooks/useContract';
import QtechStakingABI from '../abis/QtechStakingABI.json';
import CollectionABI from '../abis/CollectionABI.json';
import SETTINGS from "../SETTINGS";
import { Row, Col, Spinner } from 'react-bootstrap';
import { BrowserProvider, Contract, formatUnits } from 'ethers';
import CardNode from '../components/CardNode';
import { useNavigate } from 'react-router-dom';

function NodesList({ provider, address, isConnected }) {
  const { ownedNFTs, totalRewards, ownedNFTsLoaded, airdropPower, totalDailyRewards, distinctElements, NFTTypeCount, ownedQworlds } = useContract(provider);
  const [usdtBalance, setUsdtBalance] = useState('0.00');
  const [tokenBalance, setTokenBalance] = useState('0.00');
  const [qvrsStakes, setQvrsStakes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingBalance, setLoadingBalance] = useState(false);
  const [txMessage, setTxMessage] = useState("");
  const navigate = useNavigate();
  const [userLevel, setUserLevel] = useState(1);
  const trunTokenAddress = `${SETTINGS.tokenAddress.slice(0, 4)}...${SETTINGS.tokenAddress.slice(-1 * (4))}`;
  const trunAddress = `${SETTINGS.collection.slice(0, 4)}...${SETTINGS.collection.slice(-1 * (4))}`;
  const getUserLevel = async () => {
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    const collectionContract = new Contract(
      SETTINGS.collection,
      CollectionABI,
      signer
    );
    const level = await collectionContract.levels(signer.address);
    if (parseInt(level) > 1) {
      setUserLevel(parseInt(level));
    }
  };


  async function getBalance() {
    if (!isConnected)
      setLoadingBalance(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    try {

      await getUserLevel();
    }
    catch (error) {
      console.log("error getBalance");
      console.log(error);
    }
    setLoadingBalance(false);
  }
  const openBuyToken = () => {
    navigate('/token');
  }
  const openStakeToken = () => {
    navigate('/staking');
  }
  const openBuyNode = () => {
    navigate('/qvrs-worlds');
  }
  const openFAQs = () => {
    navigate('/FAQs');
  }
  const closeStake = async (stakeId) => {
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    try {
      setLoading(true);
      setTxMessage("Closing Stake...");
      const contract = new Contract(SETTINGS.stakingContractQtech, QtechStakingABI, signer);
      const tx = await contract.completeStake(parseInt(stakeId));
      await tx.wait();
      setTxMessage("Stake closed!");
    }
    catch (error) {
      console.error("Error closing stake:", error);
      setTxMessage("Failed to close stake. Please try again.");
    }
    finally {
      await getBalance();
      await getStakesQvrse();
      setLoading(false);
      setTimeout(() => setTxMessage(""), 5000);
    }
  };
  async function getStakesQvrse() {
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
    try {
      const contract = new Contract(SETTINGS.stakingContractQtech, QtechStakingABI, signer);
      const stakesUser = await contract.getInvestorStakes(address);
      const serialized = JSON.stringify(stakesUser, (key, value) =>
        typeof value === 'bigint' ? value.toString() : value
      );
      const stakes = JSON.parse(serialized);
      let stakesArr = [];
      for (let i = 0; i < stakes.length; i++) {
        if (stakes[i]["8"] == 1) continue
        stakesArr.push(
          {
            id: stakes[i]["0"],
            amount: formatUnits(stakes[i]["3"], 18),
            time: stakes[i]["5"],
            status: stakes[i]["8"],
          });
      }
      setQvrsStakes(stakesArr);
    }
    catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    if (isConnected) {
      getBalance();
      //getStakesQvrse();
    } else {
      setQvrsStakes([]);
      setTokenBalance("0.00");
      setUserLevel(1);
    }
  }, [isConnected, provider]);

  if (loading) {
    return (
      <div className=" text-center">
        <br />
        <br />
        <Spinner animation="border" role="status" className='loaderBig' />
        <p className='loaderMsg'>{txMessage}</p>
      </div>
    );
  }

  return (
    <>
      {/* Title */}
      <h3 className='pageTitle'>MY QNODES</h3>
      {/* My info */}
      <Row className="mt-md-5 mt-2 mb-4 justify-content-center">
        <Col sm={12} md="auto" className="mb-0 d-flex align-items-center justify-content-center" >
          <h6 className='fw-normal mb-0 pt-2'>
            <small>Generating daily:</small> <img src={logoToken} className='tokenIconColor' style={{ width: "16px", marginRight: "6px", marginLeft: "4px", marginBottom: "2px" }} alt={SETTINGS.tokenSymbol} />
            <b>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(totalDailyRewards)} {SETTINGS.tokenSymbol}</b>
          </h6>
        </Col>
        <Col sm={12} md="auto" className="mb-0 d-flex align-items-center justify-content-center px-5 mx-1" >
          <h6 className='fw-normal mb-0 pt-2'>
            <small>Unclaimed:</small> <img src={logoToken} className='tokenIconColor' style={{ width: "16px", marginRight: "6px", marginLeft: "4px", marginBottom: "2px" }} alt={SETTINGS.tokenSymbol} />
            <b>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(totalRewards)} {SETTINGS.tokenSymbol}</b>
          </h6>
        </Col>
        <Col sm={12} md="auto" className="mb-0 d-flex align-items-center justify-content-center" >
          <h6 className='fw-normal mb-0 pt-2'>
            <small>Airdrop power:</small> <img src={parachuteImg} className='tokenIconColor' style={{ width: "16px", marginRight: "6px", marginLeft: "4px", marginBottom: "2px" }} alt={SETTINGS.tokenSymbol} />
            <b>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(airdropPower)} AP</b>
          </h6>
        </Col>
      </Row>

      <hr />

      {/* QNODES */}
      <h4 className='text-center mb-4 mt-4 pt-md-3 pt-0 pb-md-2 pb-0'>MY QNODES</h4>
      <Row className="mt-0 mb-md-4 mb-0 justify-content-md-center justify-content-start">
        {ownedQworlds.map((nft, index) => (
          <Col xs={6} sm="auto" className="mb-3" key={index} style={{ padding: "0 8px" }}>
            <CardNode
              nft={nft}
              provider={provider}
              address={address}
              getBalanceFunction={getBalance}
              amount={NFTTypeCount[nft.tokenType]}
            />
          </Col>
        ))}
      </Row>

      <hr />

      {/* Exstras */}
      <h4 className='text-center mb-4 mt-4 pt-md-3 pt-0 pb-md-2 pb-0'>MY EXTRAS</h4>
      <Row className="mt-0 mb-4 justify-content-md-center justify-content-start">
        {distinctElements.map((nft, index) => (
          <Col xs={6} sm="auto" className="mb-3" key={index} style={{ padding: "0 8px" }}>
            <CardNode
              nft={nft}
              provider={provider}
              address={address}
              getBalanceFunction={getBalance}
              amount={NFTTypeCount[nft.tokenType]}
            />
          </Col>
        ))}
      </Row>

    </>
  );
}

export default NodesList;
